<script setup lang="ts">
// import CodeTool from '@editorjs/code';
// import InlineCode from '@editorjs/inline-code';
// import List from '@editorjs/list';
import EditorJS, { type BlockToolConstructable, type OutputData } from '@editorjs/editorjs';
import Header from '@editorjs/header';
import ImageTool from '@editorjs/image';
import Underline from '@editorjs/underline';
import { useDebounceFn } from '@vueuse/core';
import { getEditorData, saveEditorData, uploadImage } from '~/api/editor';
import type { Paragraph, Project } from '~/api/types';
import ru from './ru';

const props = defineProps<{
  paragraph: Paragraph;
  project: Project;
}>();

const emit = defineEmits<{
  'update:project': [project: Project];
  ready: [];
}>();

const jivoStore = useJivoStore();
const analyticsStore = useAnalyticsStore();
const editor = ref<EditorJS | null>(null);
const editorData = ref<OutputData | null>(null);
const loading = ref(false);
const editorElement = ref<HTMLElement | null>(null);
const isEdited = ref(false);
const isReady = ref(false);
onMounted(async () => {
  await nextTick();
  jivoStore.isHidden = true;
  editorData.value = await getEditorData(props.paragraph.id.toString());

  if (editorElement.value) {
    editor.value = new EditorJS({
      holder: `editor-${props.paragraph.id}`,
      data: editorData.value,
      placeholder: 'Начните писать здесь...',
      i18n: ru,
      tools: {
        paragraph: {
          config: {
            placeholder: 'Введите текст',
          },
          inlineToolbar: ['bold', 'italic'],
        },
        header: {
          class: Header as unknown as BlockToolConstructable,
          config: {
            placeholder: 'Введите заголовок',
            levels: [1, 2],
            defaultLevel: 2,
          },
          inlineToolbar: ['bold', 'italic', 'underline'],
        },
        // list: {
        //   class: List as unknown as BlockToolConstructable,
        //   config: {
        //     defaultStyle: 'unordered',
        //   },
        //    inlineToolbar: ['bold', 'italic', 'underline', 'strikethrough', 'code'],
        //   toolbox: [
        //     {
        //       icon: 'O', // OL icon
        //       title: 'Нумерованный список',
        //       data: {
        //         style: 'ordered',
        //       },
        //     },
        //     {
        //       icon: 'U', // UL icon
        //       title: 'Маркированный список',
        //       data: {
        //         style: 'unordered',
        //       },
        //     },
        //   ],
        // },
        image: {
          class: ImageTool as unknown as BlockToolConstructable,
          config: {
            uploader: {
              uploadByFile: async (file: File) => {
                const response = await uploadImage(props.paragraph.id.toString(), file);
                if (response.success === 1) {
                  return {
                    success: 1,
                    file: {
                      url: response.file.url,
                    },
                  };
                } else {
                  return {
                    success: 0,
                    error: 'Не удалось загрузить изображение',
                  };
                }
              },
            },
            features: {
              caption: 'optional',
              border: false,
              background: false,
              withBorder: false,
              stretch: false,
            },
          },
        },
        underline: {
          class: Underline as unknown as BlockToolConstructable,
        },
        // code: {
        //   class: CodeTool as unknown as BlockToolConstructable,
        //   config: {
        //     placeholder: 'Введите код',
        //   },
        //   inlineToolbar: true,
        // },
        // inlineCode: {
        //   class: InlineCode as unknown as BlockToolConstructable,
        // },
      },
      onChange: async () => {
        isEdited.value = true;
        await saveContentDebounced();
      },
      onReady: () => {
        emit('ready');
        isReady.value = true;
      },
    });
  }

  // window.addEventListener('beforeunload', saveContent);
});

onBeforeUnmount(async () => {
  if (editor.value) {
    // window.removeEventListener('beforeunload', saveContent);
    // await saveContent();
    editor.value.destroy();
    editor.value = null;
    jivoStore.isHidden = false;
  }
});

const saveContent = async () => {
  if (!isEdited.value) return;
  if (!editor.value || loading.value) return;

  const outputData = await editor.value.save();

  if (!outputData.blocks || outputData?.blocks?.length === 0) {
    throw new Error('empty output data');
    return;
  }

  // do not save if nothing changed
  // this check is necessary because EditorJS onChange callback being called even on click
  if (JSON.stringify(outputData) === JSON.stringify(editorData.value)) return;

  analyticsStore.editProjectWithEditorjs(props.project.id);
  loading.value = true;
  console.log(outputData);
  const updatedProject = await saveEditorData(props.paragraph.id.toString(), outputData);
  emit('update:project', updatedProject);
  loading.value = false;
};

const saveContentDebounced = useDebounceFn(saveContent, 5000);
</script>

<template>
  <div
    :id="String(props.paragraph.id)"
    class="content-editor"
    :class="{ hide: !isReady }"
  >
    <div
      :id="`editor-${props.paragraph.id}`"
      ref="editorElement"
      class="content-editor__container"
    />
  </div>
</template>

<style scoped lang="scss">
.hide {
  display: none;
}
.content-editor {
  min-height: 800px;
  position: relative;
  width: 100%;
  overflow: visible;
  scroll-margin-top: 100px;

  :deep(.codex-editor) {
    width: 100%;
    padding-top: 14px;
    --bg-color: #{$background-white};
    --text-color: #{$foreground-contrast};

    .codex-editor__redactor {
      margin-right: 0 !important;
      width: 100% !important;
      max-width: 100% !important;
      padding-bottom: 100px !important;
    }

    .ce-popover-item {
      &[data-item-name='convert-to'] {
        display: none;
      }
    }
    .ce-toolbar {
      .ce-toolbar__content {
        width: 100%;
        max-width: 100%;
      }
      .ce-toolbar__actions {
        left: -45px;
        z-index: 1000;
        background: transparent;
        width: 30px;

        @include media-breakpoint-down(xl) {
          left: -48px;
        }

        @include media-breakpoint-down(lg) {
          left: unset;
          width: unset;
          position: fixed;
          padding: 8px;
          width: calc(100% - 40px);
          border-radius: 16px;
          background: #2a2a2a;
          &.ce-toolbar__actions--opened {
            bottom: 80px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }

      .ce-toolbar__plus {
        @include media-breakpoint-down(lg) {
          &::after {
            font-family: 'Manrope', sans-serif;
            content: 'Добавить' !important;
            font-size: 14px;
            color: white;
            margin-left: 4px;
          }
        }
      }

      .ce-toolbar__settings-btn {
        @include media-breakpoint-down(lg) {
          &::after {
            font-family: 'Manrope', sans-serif;
            content: 'Действия' !important;
            color: white;
            font-size: 14px;
            margin-left: 4px;
          }
        }
      }

      .ce-toolbar__plus,
      .ce-toolbar__settings-btn {
        order: 4;
        color: $foreground-gray;
        background: transparent;
        width: 16px;

        // &:hover {
        //   color: $foreground-contrast;
        //   background: $background-theme-fade;
        // }

        @include media-breakpoint-down(lg) {
          width: 50%;
          background: #353334;
          border: none;
          opacity: 1;

          border-radius: 12px;
          padding: 8px;
        }
      }
    }

    .ce-inline-toolbar {
      // left: -30px !important;
      border-radius: 16px;
      border: none;
      padding: 8px;
      display: flex;
      gap: 4px;

      .ce-inline-tool {
        color: white;
        background: #353334;
        padding: 8px;
        border-radius: 12px;
        margin: 0;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 20px;
          height: 20px;
        }

        &:hover {
          background: lighten(#353334, 5%);
        }

        &--active {
          color: #5047e5;
          background: lighten(#353334, 10%);
        }
      }
    }

    .ce-conversion-toolbar {
      background: #2a2a2a;
      border-radius: 16px;
      border: none;
      padding: 8px;

      .ce-conversion-tool {
        color: white;
        padding: 8px 12px;
        border-radius: 12px;
        margin: 4px;

        &__icon {
          background: #353334;
          // padding: 8px;
          border-radius: 12px;
        }

        &:hover {
          background: transparent;

          .ce-conversion-tool__icon {
            background: lighten(#353334, 5%);
          }
        }
      }
    }

    .ce-popover__overlay {
      border-radius: 16px;
      overflow: hidden;
      opacity: 0.3;
      background: #2a2a2a;
      // &::after {
      //   content: 'Назад';
      //   color: white;
      //   text-align: center;
      //   display: absolute;
      //   font-size: 14px;
      //   margin: auto 0;
      //   top: 50%;
      //   transform: translateY(-50%);
      //   width: 100%;
      //   position: absolute;
      // }
    }

    .ce-popover {
      --width: 240px;
      --max-height: unset;

      font-family: 'Manrope', sans-serif;
      &__container {
        background: #2a2a2a;
        border: none;
        border-radius: 16px;
        padding: 8px;
        color: white;
        max-width: unset;
        // width: unset;
        @include media-breakpoint-down(lg) {
          bottom: 60px;
        }
      }

      .ce-popover-item-separator {
        display: none;
      }

      .ce-popover-header {
        &__text {
          font-size: 16px;
        }

        &__back-button {
          margin-right: 8px;
          > svg {
            color: white;
          }
        }
      }

      .cdx-search-field {
        height: 0;
        background: transparent;
        border: none;
        pointer-events: none;
        opacity: 0;
        position: absolute;
      }

      &__items {
        gap: 4px;
        display: grid;
        // flex-direction: column;

        // Set specific order for popover items
        [data-item-name='move-up'] {
          order: 1;
        }
        [data-item-name='move-down'] {
          order: 2;
        }
        [data-item-name='delete'] {
          order: 3;
        }
        [data-item-name='convert-to'] {
          order: 0;
        }

        .ce-popover-item,
        .ce-popover-item-html {
          padding: 8px;
          border-radius: 12px;
          color: white;
          background: #353334;

          &__title {
            font-size: 14px;
            margin-left: 4px;
          }

          &__icon {
            // background: #353334;

            padding: 4px;
            // &:not(:last-child) {
            //   margin-right: 10px;
            // }

            padding: 4px;
            border: 1px solid #514d4f;
            border-radius: 8px;

            @include media-breakpoint-down(lg) {
              width: 28px;
              height: 28px;
            }

            > svg {
              width: 20px !important;
              height: 20px !important;
            }
          }

          &:hover {
            background: #353334;

            .ce-popover__item-icon {
              background: lighten(#353334, 5%);
            }
          }
        }
      }

      &.ce-popover--inline {
        --height-mobile: 40px;

        .ce-popover__items {
          gap: 4px;
          display: flex;
          overflow-y: visible;

          // flex-direction: column;

          // Set specific order for popover items
          [data-item-name='move-up'] {
            order: 1;
          }
          [data-item-name='move-down'] {
            order: 2;
          }
          [data-item-name='delete'] {
            order: 3;
          }
          [data-item-name='convert-to'] {
            order: 0;
          }

          .ce-popover-item,
          .ce-popover-item-html,
          .ce-inline-tool {
            padding: 0;
            color: white;
            background: transparent;

            > svg {
              width: 24px !important;
              height: 24px !important;
            }

            .ce-popover-item__icon {
              width: 24px !important;
              height: 24px !important;
              border: none;
              padding: 0;
            }

            &:hover {
              background: transparent;
            }
          }
        }
      }
    }

    .ce-block {
      &::selection,
      *::selection {
        background-color: #e0dffb;
      }

      overflow: visible;

      .ce-block__content {
        width: 100%;
        max-width: 100%;

        .image-tool__caption {
          position: unset;
        }

        .image-tool--caption {
          padding-bottom: 20px;
        }
      }
    }

    .ce-paragraph,
    .ce-header {
      font-family: 'Times New Roman', serif;
      line-height: 26px;
      font-size: 16px;
      font-weight: 400;
      color: var(--text-color);
    }

    .ce-header {
      font-weight: 600;
      font-size: 18px;
      padding: 0;
      margin-bottom: 20px;
    }

    .cdx-quote {
      font-family: 'Times New Roman', serif;
    }

    .cdx-list {
      font-family: 'Times New Roman', serif;

      .cdx-list__item {
        padding-left: 28px;
      }
    }
  }

  &__loading {
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 4px 8px;
    background: $background-theme-fade;
    border-radius: 4px;
    font-size: 14px;
    color: $foreground-gray;
  }
}
</style>
