<script lang="ts" setup>
import dayjs from 'dayjs';
import type { FetchError } from 'ofetch';
import { onMounted } from 'vue';
import type { Project } from '~/api/types.ts';
import { changeEmail } from '~/api/user';
import VButton from '~/components/VButton.vue';
import { useUserStore } from '~/stores/UserStore';
import { getAverageTime } from '../api/projects';

const props = defineProps<{ project?: Project | null }>();

const userStore = useUserStore();
const { data: averageTime } = await useAsyncData(() => getAverageTime(), { default: () => ({ result: 50 }) });

const now = ref(dayjs.utc());
const startDate = dayjs.utc(props.project?.created_at);
const targetDate = dayjs.utc(props.project?.created_at).add(averageTime.value.result, 'seconds');
const totalDuration = targetDate.diff(startDate);
const elapsedDuration = now.value.diff(startDate);
const progress = ref(Number(Math.min(100, Math.max(0, (elapsedDuration / totalDuration) * 100)).toFixed(0)));
const interval = ref();

const updateTime = () => {
  now.value = now.value.add(1, 'second');
  const elapsedDuration = now.value.diff(startDate);
  progress.value = Number(Math.min(100, Math.max(0, (elapsedDuration / totalDuration) * 100)).toFixed(0));
};

onMounted(() => {
  interval.value = setInterval(updateTime, 1000);
});

onBeforeUnmount(() => {
  clearInterval(interval.value);
});

const email = useCookie<string | undefined>('email', { maxAge: 60 * 3, default: () => userStore.user?.email });
const isChangingEmail = ref(!userStore.user?.email);

const loading = ref(false);
const error = ref<string | null>(null);
const onEmailSubmit = async () => {
  if (email.value === userStore.user?.email || !email.value) {
    isChangingEmail.value = false;
    error.value = null;
    return;
  }

  loading.value = true;
  await changeEmail({ email: email.value })
    .then(() => {
      isChangingEmail.value = false;
      error.value = null;
      if (userStore.user) userStore.user.email = email.value!;
    })
    .catch((reason: FetchError) => {
      error.value = reason.data?.message ?? 'Не удалось сохранить Email. Проверьте корректность введенной почты или попробуйте еще раз.';
      email.value = userStore.user?.email ?? undefined;
    });
  loading.value = false;
};
</script>

<template>
  <div class="loader-page container">
    <div class="progress-bar">
      <span>{{ props.project?.display_status?.message ?? 'Подготавливаем текст' }}</span>
      <span style="font-weight: 600">{{ progress }}%</span>
    </div>

    <form class="email-container">
      <p v-if="isChangingEmail">Оставьте свою электронную почту. Как будет готово, пришлем результат.</p>
      <p v-else>
        Заказ доступен на странице <nuxt-link to="/profile">Мои заказы</nuxt-link>. <br />
        Мы отправим результат на почту <br />
        <span class="email">{{ userStore.user?.email }}</span>
      </p>
      <div
        v-show="isChangingEmail"
        class="email-form"
      >
        <input
          v-model="email"
          autofocus
          placeholder="Электронная почта"
        />
        <v-button
          :loading="loading"
          @click="onEmailSubmit"
          >Сохранить</v-button
        >
      </div>
      <span class="error">
        {{ error }}
      </span>
      <v-button
        v-show="!isChangingEmail"
        schema="secondary"
        style="width: 100%"
        @click="isChangingEmail = true"
      >
        Изменить почту
      </v-button>
    </form>
  </div>
</template>

<style lang="scss" scoped>
.loader-page {
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
  // padding-top: 100px;
  padding: 100px 20px 0 20px;
  min-height: calc(100dvh);
  display: flex;
  flex-direction: column;
  gap: 20px;

  .progress-bar {
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 32px;
    border-radius: 20px;
    font-size: 18px;
    font-weight: 600;
    line-height: 40px;
    background: $line-stroke;
    color: $foreground-theme;

    @include media-breakpoint-down(md) {
      // margin: 0 20px;
      padding: 8px 16px;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
    }

    background: url('/images/new/loader-figure.svg') rgba(#bebaff, 0.25);
    animation: bg-slide 100s linear infinite;
  }

  .email-container {
    text-align: center;
    display: flex;
    padding: 40px 80px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 20px;
    background: white;

    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    text-wrap: balance;

    @include media-breakpoint-down(md) {
      padding: 40px 16px;
      font-size: 16px;
      line-height: 1.5rem;
    }

    .error {
      font-size: 14px;
      color: $text-status-destructive;
      line-height: 1.1rem;
      text-align: start;
      text-wrap: balance;
      //margin-top: -16px;
      margin-right: auto;
    }

    .email {
      color: $foreground-gray;
    }

    .email-form {
      display: flex;
      flex-direction: row;
      gap: 12px;
      width: 100%;

      @include media-breakpoint-down(md) {
        flex-direction: column;
      }

      > input {
        border: none;
        display: flex;
        padding: 10px 20px;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        align-self: stretch;
        border-radius: 12px;
        background: $background-theme-fade;

        font-family: Manrope, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;

        &:focus-visible {
          outline: none;
        }

        ::placeholder {
          color: #9390a1;
        }

        &:disabled {
          color: #9390a1;
        }

        @include media-breakpoint-down(md) {
          font-size: 14px;
        }
      }
    }
    .email-change-button {
      color: $foreground-theme;
    }
  }
}
</style>
