<script setup lang="ts">
import type { Project, Service } from '~/api/types';
import VButton from '~/components/VButton.vue';
import { getActionBlock } from '~/api/projects';

const props = defineProps<{ project: Project; services: Service[]; variant?: 'new' | undefined }>();
const emit = defineEmits(['update-action-block']);

const MIN_LENGTH = 1;

const promocode = ref<string | undefined>();
const isValidLength = computed(() => promocode.value && promocode.value.length >= MIN_LENGTH);
const status = ref<'initial' | 'success' | 'error'>('initial');
const loading = ref<boolean>(false);

// handle existing promocode
if (props.project.action_block.promocode) {
  status.value = 'success';
  promocode.value = props.project.action_block.promocode.code;
}

const submit = async () => {
  const code = promocode.value;
  if (!isValidLength.value) return (status.value = 'error');
  if (!code || loading.value) return;
  loading.value = true;

  await getActionBlock({ promocode: code, projectId: props.project.id, services: props.services })
    .then((cart) => {
      emit('update-action-block', cart);
      status.value = 'success';
    })
    .catch(() => {
      status.value = 'error';
    });

  loading.value = false;
};

const cancel = async () => {
  if (loading.value) return;
  loading.value = true;

  await getActionBlock({ projectId: props.project.id, services: props.services })
    .then((cart) => {
      emit('update-action-block', cart);
    })
    .catch(() => {
      status.value = 'error';
    });

  loading.value = false;
  status.value = 'initial';
  promocode.value = undefined;
};
</script>

<template>
  <div class="promocode-input-container">
    <v-input
      v-model="promocode"
      style="width: 100%"
      button-right
      placeholder="PROMOCODE"
      :disabled="loading"
      :error="status === 'error'"
      :success="status === 'success'"
      @keydown.enter="submit"
      @input="status = 'initial'"
    >
      <v-button
        v-if="props.variant != 'new'"
        style="padding: 0"
        :style="{
          opacity: isValidLength && status === 'initial' ? 100 : 0,
          width: isValidLength && status === 'initial' ? '200px' : '0px',
        }"
        schema="secondary"
        :disabled="loading || status === 'success'"
        @click="submit"
      >
        Применить
      </v-button>
    </v-input>
    <span
      v-if="status === 'error'"
      style="font-size: 14px; color: #e64141; padding-left: 4px"
    >
      Промокод не найден
    </span>
    <template v-if="props.variant != 'new'">
      <v-button
        v-if="status === 'success'"
        schema="text"
        :disabled="loading"
        @click="cancel"
      >
        Отменить
      </v-button>
    </template>
    <template v-if="props.variant === 'new'">
      <v-button
        v-if="status != 'success'"
        style="width: 100%"
        medium
        schema="primary"
        :disabled="loading || !(isValidLength && status === 'initial')"
        @click="submit"
      >
        Применить
      </v-button>
      <v-button
        v-else
        schema="outline"
        medium
        style="width: 100%"
        :disabled="loading"
        @click="cancel"
      >
        Отменить
      </v-button>
    </template>
  </div>
</template>

<style scoped lang="scss">
.promocode-input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
}
</style>
