<script setup lang="ts">
import VModal from '~/components/common/VModal.vue';
import type { Project, Service } from '~/api/types';
import PromocodeInput from '~/components/pages/projects/PromocodeInput.vue';

const props = defineProps<{ project: Project; services: Service[] }>();
const emit = defineEmits(['update-action-block']);
const open = ref(false);

const onOpen = () => {
  open.value = true;
};

const onClose = () => {
  open.value = false;
};
</script>

<template>
  <v-button
    schema="outline"
    class="promocode-button"
    medium
    @click="onOpen"
  >
    У меня есть промокод
  </v-button>
  <v-modal
    :open="open"
    title="Ввести промокод"
    closable
    @close="onClose"
  >
    <div class="promocode-modal">
      <promocode-input
        variant="new"
        :project="props.project"
        :services="props.services"
        style="padding: 0; width: 100%"
        @update-action-block="
          (value) => {
            emit('update-action-block', value);
            onClose();
          }
        "
      />
      <!--      <v-button-->
      <!--        medium-->
      <!--        style="width: 100%; margin-top: 12px"-->
      <!--        @click="onClose"-->
      <!--      >-->
      <!--        Закрыть-->
      <!--      </v-button>-->
    </div>
  </v-modal>
</template>

<style scoped lang="scss">
.promocode-button {
  width: 100%;
  margin-top: 4px;

  &:not(:hover) {
    background: white;
  }
}

.promocode-modal {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
}
</style>
