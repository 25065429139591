<script setup lang="ts">
import { X } from 'lucide-vue-next';
import type { Project } from '~/api/types';
import RateModal from '~/components/rating/RateModal.vue';
import StarRating from '~/components/rating/StarRating/StarRating.vue';
const props = defineProps<{
  project: Project;
}>();

const rating = ref(0);
const isRateModalOpen = ref(false);
const isOpen = ref(false);
const openRateModal = () => {
  isRateModalOpen.value = true;
};

const close = () => {
  isOpen.value = false;
};

const isVisible = computed(() => {
  const isOwner =
    props.project && props.project.payed_status && props.project.is_owner && !props.project.long_poll && !props.project.rated_dt;
  return isOwner && isOpen.value;
});

onMounted(() => {
  setTimeout(() => {
    isOpen.value = true;
  }, 3000);
});
</script>

<template>
  <transition name="slide-left">
    <div
      v-if="isVisible"
      class="floating-rate-card"
    >
      <div class="floating-rate-card__content">
        <div class="floating-rate-card__header">
          <h3 class="floating-rate-card__title">Оцените проект</h3>
          <v-button
            schema="text"
            class="floating-rate-card__close"
            @click="close"
          >
            <X />
          </v-button>
        </div>
        <div
          class="floating-rate-card__stars"
          @click="openRateModal"
        >
          <star-rating v-model="rating" />
        </div>
      </div>
    </div>
  </transition>
  <rate-modal
    :open="isRateModalOpen"
    :project="props.project"
    :initial-value="rating"
    @close="isRateModalOpen = false"
  />
</template>

<style lang="scss" scoped>
.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.5s ease;
}

.slide-left-enter-from,
.slide-left-leave-to {
  opacity: 0;
  transform: translateX(150%) scale(0.9);
}

.slide-left-leave-from,
.slide-left-enter-to {
  transform: translateX(0) scale(1);
}

.floating-rate-card {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 1000;
  background-color: $background-white;
  border-radius: 32px 0 0 32px;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.05);
  width: 211px;

  // animation-name: slide-left;
  // animation-fill-mode: forwards;
  // animation-play-state: running;
  // animation-direction: normal;
  // animation-timing-function: ease-out;
  // animation-duration: 200ms;
  // animation-delay: 3s;
  // animation-iteration-count: 1;

  .floating-rate-card__content {
    padding: 24px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: start;
    user-select: none;

    .floating-rate-card__header {
      display: flex;
      flex-direction: row;
      width: 100%;

      .floating-rate-card__title {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.1rem;
        margin: 0;
      }

      .floating-rate-card__close {
        position: absolute;
        right: 16px;
        top: 16px;
      }
    }

    display: flex;
    width: 100%;

    .floating-rate-card__stars {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
      gap: 4px;
      cursor: pointer;
    }
  }
}

@keyframes slide-left {
  from {
    transform: translateX(200%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
