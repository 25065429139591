<script setup lang="ts">
import type { PayItem, Project } from '~/api/types';
import VButton from '~/components/VButton.vue';
import VDrawer from '~/components/common/VDrawer.vue';
import VDropdown from '~/components/common/VDropdown.vue';
import NewPaymentButton from '~/components/pages/projects/payment/NewPaymentButton.vue';

type Props = {
  project: Project;
  cart: PayItem[];
};

const props = defineProps<Props>();

const defaultPrice = computed(() => props.cart.reduce((sum: number, item: PayItem) => sum + item.price, 0));
const discountPrice = computed(() => props.cart.reduce((sum: number, item: PayItem) => sum + (item.discount_price ?? item.price), 0));

const loading = ref(false);
const setIsLoading = (value: boolean) => {
  console.log(value);
  loading.value = value;
};

const drawerOpen = ref(false);
const onDrawerClose = () => (drawerOpen.value = false);
const onPaymentClick = () => {
  drawerOpen.value = true;
};
</script>

<template>
  <div>
    <v-dropdown
      class="payment-dropdown"
      @open="onPaymentClick"
    >
      <v-button
        schema="primary"
        medium
        style="width: 100%"
        :loading="loading"
        :disabled="loading"
      >
        {{ props.project.action_block.buttonText || 'Оплатить' }}
        <span
          :style="{
            opacity: discountPrice < defaultPrice ? 0.3 : 1,
            'text-decoration': discountPrice < defaultPrice ? 'line-through' : 'none',
          }"
        >
          {{ defaultPrice }}&nbsp;₽
        </span>
        <span v-if="discountPrice < defaultPrice"> {{ discountPrice }}&nbsp;₽ </span>
      </v-button>
      <template #body>
        <div
          style=""
          class="payment-dropdown-content"
        >
          <!-- <NewPaymentButton
            :cart="props.cart"
            :project="props.project"
            type="sbp"
            @loading="setIsLoading"
          /> -->
          <NewPaymentButton
            :cart="props.cart"
            :project="props.project"
            type="ru"
            @loading="setIsLoading"
          />
          <NewPaymentButton
            :cart="props.cart"
            :project="props.project"
            type="en"
            @loading="setIsLoading"
          />
        </div>
      </template>
    </v-dropdown>
    <v-drawer
      v-if="!$device.isDesktop"
      :open="drawerOpen"
      title="Выберите способ оплаты"
      class="modal-drawer"
      @close="onDrawerClose"
    >
      <div
        style=""
        class="payment-drawer-content"
      >
        <!-- <NewPaymentButton
          :cart="props.cart"
          :project="props.project"
          type="sbp"
          @loading="setIsLoading"
        /> -->
        <NewPaymentButton
          :cart="props.cart"
          :project="props.project"
          type="ru"
          @loading="setIsLoading"
        />
        <NewPaymentButton
          :cart="props.cart"
          :project="props.project"
          type="en"
          @loading="setIsLoading"
        />
      </div>
    </v-drawer>
  </div>
</template>

<style lang="scss">
.payment-dropdown {
  width: 100%;
  .dropdown__body {
    margin-top: 0 !important;
  }
}

.payment-dropdown-content {
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 6px;
  min-width: 280px;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.payment-drawer-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 6px;

  > * {
    width: 100%;
  }
}
</style>
