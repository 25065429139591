<script setup lang="ts">
import { getActionBlock } from '~/api/projects';
import type { ActionBlock, PayItem, Project } from '~/api/types';
import ActionBlockTimer from '~/components/pages/projects/actionBlock/ActionBlockTimer.vue';
import CopyActionBlock from '~/components/pages/projects/actionBlock/CopyActionBlock.vue';
import FixedActionBlock from '~/components/pages/projects/actionBlock/FixedActionBlock.vue';
import PaymentActionBlock from '~/components/pages/projects/actionBlock/PaymentActionBlock.vue';
import BlackFridayTimer from '~/components/promo/black-friday/BlackFridayTimer.vue';
import { useJivoStore } from '~/stores/JivoStore';

const props = defineProps<{ project: Project }>();
const cart = ref<PayItem[]>([...props.project.action_block.items.filter((item: PayItem) => !item.optional || item.selected)]);
const actionBlockRef = ref<HTMLDivElement | null>(null);
const jivoStore = useJivoStore();

onMounted(() => {
  jivoStore.bottomOffset = 80;
});

const updateActionBlock = async (newActionBlock?: ActionBlock) => {
  if (newActionBlock) {
    props.project.action_block = newActionBlock;
  } else {
    props.project.action_block = await getActionBlock({
      projectId: props.project.id,
      services: cart.value.map((item) => item.service),
    });
  }
};

const updateCart = (newCart: Array<PayItem>) => {
  cart.value = newCart;
};
</script>

<template>
  <div
    ref="actionBlockRef"
    class="action-block-wrapper"
    :style="{
      'scroll-margin-top':
        props.project.action_block.promocode_timer || props.project.action_block.promocode?.code === 'FRIDAY' ? '52px' : '0px',
    }"
  >
    <black-friday-timer
      v-if="props.project.action_block.type === 'link' && props.project.action_block.promocode?.code === 'FRIDAY'"
      :project="project"
      :cart="cart"
    />
    <action-block-timer
      v-if="props.project.action_block.promocode_timer"
      :target-date="props.project.action_block.promocode_timer.expired_at"
      :start-date="props.project.created_at"
      :label="props.project.action_block.promocode_timer.title"
      :now="props.project.action_block.promocode_timer.now"
      minutes
      seconds
      :project="project"
      :cart="cart"
      @update-action-block="updateActionBlock"
    />
    <payment-action-block
      v-if="props.project.action_block.type == 'link'"
      :project="project"
      :cart="cart"
      @update-cart="updateCart"
      @update-action-block="updateActionBlock"
    />
    <copy-action-block
      v-else
      :project="project"
    />
  </div>
  <fixed-action-block
    :action-block-ref="actionBlockRef"
    :project="project"
  />
</template>

<style scoped lang="scss">
.action-block-wrapper {
  display: flex;
  flex-direction: column;
  gap: 18px;
  box-sizing: content-box;
}
</style>
