<script setup lang="ts">
import { rateProject } from '~/api/projects';
import type { EntityType, Project } from '~/api/types.ts';
import ProjectCard from '~/components/cards/ProjectCard.vue';
import StarRating from '~/components/rating/StarRating/StarRating.vue';
import VButton from '~/components/VButton.vue';
import { entities } from '~/enitityHelper';

const props = defineProps<{ project: Project; initialValue?: number }>();
const emit = defineEmits(['close', 'show-sorry', 'show-otzovik']);

const texts = entities[(props.project.entity as EntityType) || 'project'];

const rate = ref<number>(props.initialValue ?? 5);
const comment = ref<string>();
const loading = ref();
const textareaLength = ref(100);

const submit = async () => {
  if (!rate.value) {
    return;
  }
  loading.value = true;
  await rateProject(props.project.id, rate.value, comment.value);
  await refreshNuxtData('project');
  loading.value = false;
  emit('close');
  if (rate.value <= 3) emit('show-sorry');
  else emit('show-otzovik', [comment.value]);
};
</script>

<template>
  <div class="review-rating">
    <div>
      <h5>{{ texts.rateCard.title }}</h5>
      <ProjectCard
        v-if="project"
        :schema="project.payed_status ? 'pro' : 'default'"
        :project-card="project"
        class="review-rating__project-card"
      />
    </div>
    <div class="review-rating__stars">
      <h6>Оценка</h6>
      <star-rating
        v-model="rate"
        :star-size="30"
      />
    </div>
    <div class="review-rating__action">
      <div class="review-rating__action-top">
        <h6>Отзыв</h6>
        <div class="review-rating__action-limit">
          {{ comment?.length ?? 0 }}<span>/{{ textareaLength }}</span>
        </div>
      </div>
      <new-textarea
        v-model="comment"
        :maxlength="textareaLength"
        placeholder="Напишите ваш отзыв"
        :rows="3"
      />
    </div>
    <v-button
      :disabled="!rate || loading"
      class="w-100"
      small
      @click="submit"
    >
      Отправить
    </v-button>
  </div>
</template>

<style scoped lang="scss">
.review-sent {
  display: flex;
  gap: 12px;
  align-items: center;
}

.review-rating {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  h5 {
    margin-bottom: 12px;
  }

  h6 {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  .review-rating__project-card {
    background: $background-theme-fade;
  }

  .review-rating__action {
  }

  .review-rating__action-top {
    h6 {
      margin-bottom: 0;
    }

    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .review-rating__action-limit {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;

    span {
      color: $foreground-gray;
    }
  }
}
</style>
