<script setup lang="ts">
import type { Project } from '~/api/types.ts';
import VModal from '~/components/common/VModal.vue';
import ReviewRating from '~/components/rating/ReviewRating.vue';
import SorryModal from '~/components/rating/SorryModal.vue';

const props = defineProps<{ project: Project; open: boolean; initialValue?: number }>();

const isSorryModalOpen = ref(false);
const isOtzovikModalOpen = ref(false);
const isRatingModalOpen = ref(true);

const emit = defineEmits(['close']);
watch(
  () => !isSorryModalOpen.value && !isRatingModalOpen.value,
  (allModalsClosed) => {
    if (allModalsClosed) {
      emit('close');
      isRatingModalOpen.value = true;
    }
  }
);
</script>

<template>
  <v-modal
    :open="isRatingModalOpen && props.open"
    @close="isRatingModalOpen = false"
  >
    <review-rating
      :project="props.project"
      :initial-value="props.initialValue"
      @show-sorry="isSorryModalOpen = true"
      @show-otzovik="isOtzovikModalOpen = true"
      @close="isRatingModalOpen = false"
    />
  </v-modal>
  <sorry-modal
    :open="isSorryModalOpen && props.open"
    @close="isSorryModalOpen = false"
  />
</template>

<style lang="scss"></style>
