import type { I18nConfig } from '@editorjs/editorjs';

const ru: I18nConfig = {
  messages: {
    ui: {
      blockTunes: {
        toggler: {
          'Click to tune': 'Нажмите, чтобы настроить',
          'or drag to move': 'или перетащите, чтобы переместить',
        },
      },
      inlineToolbar: {
        converter: {
          'Convert to': 'Изменить',
        },
      },
      toolbar: {
        toolbox: {
          Add: 'Добавить',
          Filter: 'Фильтр',
          'Nothing found': 'Ничего не найдено',
        },
      },
      popover: {
        Filter: 'Фильтр',
        'Nothing found': 'Ничего не найдено',
        'Convert to': 'Изменить',
        Convert: 'Изменить',
      },
    },
    toolNames: {
      Text: 'Текст',
      Heading: 'Заголовок',
      List: 'Список',
      Link: 'Ссылка',
      Bold: 'Жирный',
      Italic: 'Курсив',
      Underline: 'Подчеркнутый',
      Strikethrough: 'Зачеркнутый',
      Code: 'Код',
      InlineCode: 'Код',
      Image: 'Изображение',
    },
    tools: {
      link: {
        'Add a link': 'Добавить ссылку',
      },
      stub: {
        'The block can not be displayed correctly.': 'Блок не может быть отображен корректно.',
      },
      header: {
        'Enter a header': 'Введите заголовок',
        'Heading 1': 'Заголовок 1',
        'Heading 2': 'Заголовок 2',
      },
      image: {
        'Select an Image': 'Выберите изображение',
        'With URL': 'По ссылке',
        'With File': 'Загрузить файл',
        'Select file': 'Выберите файл',
        'With border': 'С рамкой',
        'Stretch image': 'Растянуть изображение',
        'With background': 'С фоном',
        'With caption': 'Добавить подпись',
      },
      list: {
        Ordered: 'Нумерованный список',
        Unordered: 'Маркированный список',
      },
      paragraph: {
        'Enter something': 'Начните писать здесь...',
      },
    },
    blockTunes: {
      delete: {
        Delete: 'Удалить',
        'Click to delete': 'Нажмите, чтобы удалить',
      },
      moveUp: {
        'Move up': 'Вверх',
      },
      moveDown: {
        'Move down': 'Вниз',
      },
    },
  },
};

export default ru;
