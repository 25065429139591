import { type OutputData } from '@editorjs/editorjs';
import { getApiEndpoint } from '~/api/auth';
import type { Project } from '~/api/types';
import { authFetch } from '~/api/utils';

export const getEditorData = async (paragraph_id: string): Promise<OutputData> => {
  const response = await authFetch(getApiEndpoint() + `editor_js/${paragraph_id}/get`, {
    method: 'GET',
  });
  console.log(response);
  return response as OutputData;
};

export const saveEditorData = async (paragraph_id: string, data: OutputData): Promise<Project> => {
  return await authFetch(getApiEndpoint() + `editor_js/${paragraph_id}/save/`, {
    method: 'POST',
    body: { data: data },
  });
};

export const uploadImage = async (paragraph_id: string, file: File): Promise<{ success: 0 } | { success: 1; file: { url: string } }> => {
  const formData = new FormData();
  formData.append('image', file);
  return await authFetch(getApiEndpoint() + `editor_js/${paragraph_id}/upload/`, {
    method: 'POST',
    body: formData,
  });
};

export const resetEditorData = async (paragraph_id: string): Promise<Project> => {
  return await authFetch(getApiEndpoint() + `editor_js/${paragraph_id}/reset/`, {
    method: 'POST',
  });
};
